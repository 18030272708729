.Carousel {
    width: 800px;
}

.slider {
    display: flex;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
  }
  .carousel-img {
    margin: 0 20px;
    scroll-snap-align: center;
    flex-shrink: 0;
    width: 700px;
  }