.Intro {
    font-family: 'Open Sans';
    z-index: 1;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: black;
    color: white;
    animation-name: fadeout;
    animation-delay: 2.6s;
    animation-duration: .01s;
    animation-fill-mode: forwards;
}

.span {
    font-size: 54px;
    position: absolute;
}

#one {
    z-index: 4;
    background: black;
    padding-left: 150px;
    bottom: 50%;
    right: calc(50vw + 12px);
    animation-name: one;
    animation-delay: 0.5s;
    animation-duration: 2s;
    animation-fill-mode: forwards;
}

#two {
    z-index: 3;
    bottom: 50%;
    right: calc(50vw + 12px);
    animation-name: two;
    animation-delay: 2.1s;
    animation-duration: 0.4s;
    animation-fill-mode: forwards;

}

#three {
    z-index: 2;
    background: black;
    padding-left: 200px;
    bottom: 50%;
    right: calc(50vw - 35px);
    animation-name: three;
    animation-delay: 2.2s;
    animation-duration: 0.4s;
    animation-fill-mode: forwards;
}

#four {
    z-index: 1;
    bottom: 50%;
    right: calc(50vw - 33px);
    animation-name: four;
    animation-delay: 0.5s;
    animation-duration: 2.1s;
    animation-fill-mode: forwards;
}

@keyframes one {
    from {
        right: calc(50vw + 12px);
    }
    40% {
        right: calc(50vw + 182px);
    }
    80% {
        right: calc(50vw + 182px);
        opacity: 1;
    }
    to {
        right: calc(50vw + 197px);
        opacity: 0;
    }
}

@keyframes two {
    from {
        right: calc(50vw + 12px);
    }
    to {
        right: calc(50vw + 27px);
        opacity: 0;
    }
}

@keyframes three {
    from {
        right: calc(50vw - 35px);
    }
    to {
        right: calc(50vw - 19px);
        opacity: 0;
    }
}

@keyframes four {
    from {
        right: calc(50vw - 34px);
    }
    38.0952380952% {
        right: calc(50vw - 255px);
    }
    80.9523809524% {
        right: calc(50vw - 255px);
        opacity: 1;
    }
    to {
        right: calc(50vw - 240px);
        opacity: 0;
    }
}

@keyframes fadeout {
    from {
        opacity: 1;
    }
    99% {
        z-index: 1
    }
    to {
        opacity: 0;
        z-index: -1;
    }
}