.Header {
    font-family: 'Open Sans';
    color: white;
    display: flex;
    position: fixed;
    top: 0vh;
    width: 100vw;
    height: 9vh;
    align-items: center;
    justify-content: flex-start;
    background: black;
    z-index: 5;
}

#title-box {
    display: flex;
    position:relative;
    height: 9vh;
    width: 320px;
    align-items: center;
    justify-content: center;
    margin: 0;
    background: white;
    opacity: 0;
    animation-name: come-in;
    animation-delay: 0.05s;
    animation-duration: .5s;
    animation-fill-mode: forwards;
}

#title {
    position: relative;
    color: black;
}

.link-buttons {
    height: 100%;
}

.link-button {
    display: inline-block;
    font-family: 'Open Sans';
    font-weight: 600;
    background: none;
    color: white;
    border: none;
    font-size: 115%;
    margin-left: 35px;
    height: 100%;
    border-bottom: none;
    text-decoration: none;
    vertical-align: middle;
}

.link-button:hover > .link {
    color:rgb(52, 123, 145);
}

.link {
    color: white;
    text-decoration: none;
}

.link:visited {
    color: white;
}

.link-button > span > a:link, .link-button > span > a:visited, .link-button > span > a:active {
    color: white;
    text-decoration: none;
}

.link-button:hover {
    border-bottom: white solid;
    color: rgb(52, 123, 145);
}

.link-button:hover > span > a:link, .link-button:hover > span > a:visited, .link-button:hover > span > a:active {
    color: rgb(52,123,145);
}

#work-btn {
    opacity: 0;
    animation-name: come-in;
    animation-delay: 0.1s;
    animation-duration: .5s;
    animation-fill-mode: forwards;
}

#about-btn {
    opacity: 0;
    animation-name: come-in;
    animation-delay: 0.15s;
    animation-duration: .5s;
    animation-fill-mode: forwards;
}

#github-btn {
    opacity: 0;
    animation-name: come-in;
    animation-delay: 0.2s;
    animation-duration: .5s;
    animation-fill-mode: forwards;
}

#linkedin-btn {
    opacity: 0;
    animation-name: come-in;
    animation-delay: 0.25s;
    animation-duration: .5s;
    animation-fill-mode: forwards;
}

#email-btn {
    opacity: 0;
    animation-name: come-in;
    animation-delay: 0.3s;
    animation-duration: .5s;
    animation-fill-mode: forwards;
}

@keyframes come-in {
    from {
        right: 15px;
        opacity: 0;
    }
    to {
        right: 0;
        opacity: 1;
    }
}