.Work {
    position: relative;
    top: 9vh;
    height: 91vh;
    width: 100vw;
    background: black;
    display:flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
}

#card-holder {
    width: calc(380px * 2);
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}

.big-card {
    position: relative;
    opacity: 0;
    height: 492.5px;
    width: 360px;
}

.card {
    border-radius: 20px;
    height: 492.5px;
    width: 360px;
    background: white;
    display: inline-block;
}

.card-buttons {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 60px;
    width: 25vw;
    text-align: right;
}

.card-button {
    margin-right: 16px;
    color: black;
    font-size: 200%;
    background: none;
    border: none;
}

/* First Card */
#big-card-northlake-timecards {
    animation-name: come-in;
    animation-delay: 0.2s;
    animation-duration: .5s;
    animation-fill-mode: forwards;
}

#card-northlake-timecards {
    position: relative;
    background-color: rgb(227, 224, 217);
    color: black;
    text-align: center;
}

#card-northlake-timecards > h2 {
    margin: 0;
    font-size:300%;
    font-weight: 250;
    font-family: 'Montserrat', sans-serif;
}

#card-northlake-timecards > h1:nth-child(1) {
    margin-top: 23%;
}

#card-northlake-timecards > img {
    margin: 27% 1.5px 18% 1.5px;
    width: 285.6px;
    height: 48.8px;
}

#big-card-northlake-timecards:hover > .card {
    box-shadow: 0 0 20px 10px rgb(204, 185, 129);
}

#card-button-github-northlake-timecard:hover > svg {
    color: rgb(204, 185, 129);
}

/* Second Card */
#big-card-uw-bootcamp-projects {
    animation-name: come-in;
    animation-delay: 0.3s;
    animation-duration: .5s;
    animation-fill-mode: forwards;
}

#card-uw-bootcamp-projects {
    position: relative;
    background: repeating-linear-gradient(
        to right,
        rgb(51, 0, 111),
        rgb(51, 0, 111) 120px,
        rgb(145,123,76) 120px,
        rgb(145,123,76) 240px
    );
    color: rgb(235, 222, 168);
    text-align: center;
}

#card-uw-bootcamp-projects > img {
    margin: 15% 0 5% 0;
    width: 160px;
    height: 130px;
}

#card-uw-bootcamp-projects > h1 {
    font-family: 'Encode Sans', sans-serif;
}

#big-card-uw-bootcamp-projects:hover > .card {
    box-shadow: 0 0 20px 10px rgb(86, 65, 110);
}

@keyframes come-in {
    from {
        right: 15px;
        opacity: 0;
    }
    to {
        right: 0;
        opacity: 1;
    }
}

#portfolio-github {
    font-weight: bolder;
    position: absolute;
    bottom: 3vh;
    right: 3vh;
    color: white;
    background: none;
    border: none;
    font-size: 100%;
    opacity: 0;
    animation-name: come-in;
    animation-delay: 0.35s;
    animation-duration: .5s;
    animation-fill-mode: forwards;
}

#portfolio-github:hover {
    color:rgb(52, 123, 145);
}