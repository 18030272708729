.NLCCTimecards {
    width: 100%;
    margin-top: 30px;
    z-index: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.qa {
    padding: 40px;
    width: 1100px;
    display: flex;
    margin-top: 20px;
    border-radius: 20px;
}

.teal {
    background-color: rgb(1, 165, 149);
}

.white-text {
    color: white;
}

.white {
    background-color: white;
    color: rgb(1, 165, 149);
}

.question {
    padding: 15px;
    border-radius: 40px;
    width: 270px;
    margin-right: 20px;
    margin: auto 0px;
}

.answer {
    padding: 20px;
    border: 2px rgb(1, 165, 149) solid;
    border-radius: 10px;
    width: 700px;
    margin: 20px 25px;
    background-color: white;
}