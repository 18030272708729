.Heading {
    display: flex;
    justify-content: center;
    width: 100vw;
    height:140px;
}

.heading-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 100vw;
    max-width: 1500px;
    height: 100%;
}

.cd-logo {
    width: 60px;
    margin-left: 40px;
}

.nav-btn {
    color: black;
    text-decoration: none;
    margin: 15px;
    font-size:120%
}

#nav-contact {
    color: rgb(1, 165, 149);
    border: 1px solid rgb(1, 165, 149);
    border-radius: 28px;
    padding: 10px;
    margin-right: 40px;
}

.nav-btn:visited {
    color: black
}